
import { Options, Vue } from 'vue-class-component'
import axios from 'axios'
import { ElMessage } from 'element-plus'
// import HelloWorld from '@/components/HelloWorld.vue' // @ is an alias to /src

@Options({
  data() {
    return {
      show: false,
      form: {},
      token: '',
      baseUrl: 'https://shuotengapi.qtdatas.com',
      // baseUrl: 'http://localhost:3000',
      tableData: [],
      type: '',
      project: '',
      deliverType: '',
      dataType: [
        { lable: '销售数据', value: 'xiao' },
        { lable: '采购数据', value: 'jin' },
        { lable: '库存数据', value: 'cun' },
        { lable: '欢乐扫数据', value: 'scanData' }
      ],
      deliverTypes: [
        { lable: '日报', value: '日报' },
        { lable: '月报', value: '月报' }
      ],
      projects: [
        { lable: '宠物', value: '宠物' },
        { lable: '畜牧', value: '畜牧' },
        { lable: '家禽', value: '家禽' },
        { lable: '奶牛', value: '奶牛' }
      ]
    }
  },
  methods: {
    getFileList(): void {
      if (this.type && this.project && this.deliverType) {
        axios({
          url: `${this.baseUrl}/api/files`,
          headers: {
            'access-token': this.token
          },
          params: {
            type: this.type,
            project: this.project,
            deliverType: this.deliverType
          }
        }).then((res) => {
          if (res.data.code === 0) {
            this.tableData = res.data.data
          } else {
            ElMessage({
              type: 'warning',
              message: res.data.msg
            })
            // this.show = true
          }
        })
      }
    },
    login(): void {
      axios
        .post(`${this.baseUrl}/api/login`, {
          name: this.form.name,
          password: this.form.password,
          type: 'admin'
        })
        .then((res) => {
          ElMessage({
            type: res.data.code === 0 ? 'success' : 'warning',
            message: res.data.msg
          })
          if (res.data.code === 0) {
            this.saveItem('token', res.data.data)
            this.show = false
            this.token = res.data.data.token
            this.getFileList()
          }
        })
    },
    saveItem(key: string, value: any) {
      const storageKey = 'qt_Token_key'
      if (!key) {
        return
      }
      const _storageKey = JSON.parse(
        window.localStorage.getItem(storageKey) || '{}'
      )
      _storageKey[key] = value
      window.localStorage.setItem(storageKey, JSON.stringify(_storageKey))
    },
    getItem(key: string) {
      const storageKey = 'qt_Token_key'
      if (!key) {
        return
      }
      const _storageKey = JSON.parse(
        window.localStorage.getItem(storageKey) || '{}'
      )
      return _storageKey[key] || ''
    },
    // 上传文件
    uploadFile(file: any) {
      if (!this.project) {
        ElMessage('项目不能为空')
        return
      }
      if (!this.deliverType) {
        ElMessage('请先选择交付的报告类型')
        return
      }
      if (!this.type) {
        ElMessage('请先选择交付的数据类型')
        return
      }
      const formData = new FormData()
      formData.append('file', file.file)
      formData.append('type', this.type)
      formData.append('project', this.project)
      formData.append('deliverType', this.deliverType)
      axios({
        url: `${this.baseUrl}/api/upload/file`,
        method: 'post',
        headers: {
          'access-token': this.token,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: formData
      }).then((res) => {
        ElMessage({
          type: res.data.code === 0 ? 'success' : 'warning',
          message: res.data.msg
        })
        if (res.data.code === 0) {
          this.getFileList()
        }
      })
    },
    downloadFile(url: string) {
      if (this.token) {
        axios({
          url: `${this.baseUrl}/api/file/url`,
          headers: {
            'access-token': this.token
          },
          params: { url }
        }).then((res) => {
          if (res.data.code === 0) {
            window.open(`${this.baseUrl}/${url}`, '_self')
          }
        })
      }
    }
  },
  created() {
    const token = this.getItem('token')
    this.token = token.token
    if (!token) {
      this.show = true
    }
  }
})
export default class Home extends Vue {}

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import components from '@/components'
import layouts from '@/layout'
// import { ElButton, ElTable, ElMessage } from 'element-plus'
import elementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '@/style/init.scss'
const app = createApp(App)
app.use(elementPlus)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
components.map((component: any) => app.component(component.name, component))
// eslint-disable-next-line @typescript-eslint/no-explicit-any
layouts.map((layout: any) => app.component(layout.name, layout))

app.use(store).use(router).mount('#app')

<template>
  <div class="header">
    <!-- <div class="logo"></div> -->
    <div class="menus">
      <div class="img">
        <!-- <img alt="Vue logo" src="../assets/logo.jpg" height="60" /> -->
        手工流向采集工具
      </div>
      <div>
        <el-menu :default-active="activeIndex" mode="horizontal" :ellipsis="false" @select="handleSelect">
          <router-link to="/">
            <el-menu-item index="1">数据上传</el-menu-item>
          </router-link>
          <router-link to="/data">
            <el-menu-item index="2" router="/data">查看数据</el-menu-item>
          </router-link>
        </el-menu>
      </div>
    </div>
    <div class="userName">
      <el-avatar class="avatar bg1">管</el-avatar>
      <div>管理员</div>
    </div>
  </div>
</template>

<script>
import { Options, Vue } from 'vue-class-component'

@Options({
  name: 'Header',
  data () {
    return {
      activeIndex: 1
    }
  },
  methods: {
    handleSelect () {
      console.log('===')
    }
  }
})
export default class Header extends Vue { }

</script>

<style lang="scss" scoped>
.header {
  height: 60px;
  line-height: 60px;
  background-color: #17223b;
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
  .menus {
    max-height: 90%;
    min-height: 90%;
    display: flex;
    --el-color-white: #17223b;
    --el-color-primary-light-9: #17223b;
    --el-menu-background-color: #17223b;
    --el-menu-hover-background-color: #17223b;
    --el-text-color-primary: #fff;
    .el-menu--horizontal {
      border-bottom: none;
    }
    .img {
      display: flex;
      color: #fff;
      height: 60px;
      // line-height: 60px;
      text-align: center;
      padding: 0 30px;
      // border: 1px solid #fff;
    }
    .el-menu--horizontal > .el-menu-item.is-active {
      background-color: #6b778d;
      color: #fff !important;
      font-weight: 500;
    }
  }
  .userName {
    text-align: right;
    display: flex;
    justify-items: center;
    justify-content: right;
    align-items: center;
    color: #fff;
    min-width: 130px;
    .avatar {
      margin-right: 10px;
      font-size: 18px;
      font-weight: 500;
    }
    // 148,00,00
    .bg1 {
      background-color: rgba(148, 000, 000, 0.8);
    }
  }
}
</style>
